import { jsx as _jsx } from "react/jsx-runtime";
import Plotly from "plotly.js-dist-min";
import { useEffect, useRef } from "react";
import "./SankeyDiagram.css";
export const SankeyDiagram = ({ customData, labels, links, positions }) => {
    const chartRef = useRef(null);
    const formatTooltipValues = (value) => {
        if (value < 1)
            return "No Data available";
        return `${Math.round(value)} kWh`;
    };
    const getIncomingSummary = (index) => {
        let incoming = 0;
        let noData = false;
        links.value.forEach((value, valueIndex) => {
            if (links.target[valueIndex] !== index) {
                noData = true;
            }
            incoming = incoming + value;
        });
        if (noData !== false) {
            return "No Data Available";
        }
        return formatTooltipValues(incoming);
    };
    const getOutgoingSummary = (index) => {
        let outgoing = 0;
        let noData = false;
        links.value.forEach((value, valueIndex) => {
            if (links.source[valueIndex] !== index) {
                noData = true;
            }
            outgoing = outgoing + value;
        });
        if (noData !== false) {
            return "No Data Available";
        }
        return formatTooltipValues(outgoing);
    };
    const modifiedCustomData = customData.map((custom, index) => {
        return {
            id: custom,
            income: getIncomingSummary(index),
            outcome: getOutgoingSummary(index)
        };
    });
    const data = [
        {
            arrangement: "snap",
            link: {
                ...links,
                hovertemplate: "<extra></extra>"
            },
            node: {
                customdata: modifiedCustomData,
                hovertemplate: "ID: %{customdata.id}<br />Charge Energy: %{customdata.income}<br />Discharge Energy: %{customdata.outcome}<extra></extra>",
                label: labels,
                x: positions.x,
                y: positions.y
            },
            type: "sankey"
        }
    ];
    const layout = {
        autoresize: true,
        font: {
            size: 14
        }
    };
    const config = {
        displayModeBar: false,
        responsive: true
    };
    useEffect(() => {
        if (chartRef.current) {
            Plotly.newPlot(chartRef.current, data, layout, config);
        }
    }, [customData]);
    return _jsx("div", { className: "sankey-component", ref: chartRef });
};
