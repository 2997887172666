import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CatHeading } from "blocks-nextjs/blocks-react/components/Heading/Heading";
import { CatIconArrowDown } from "blocks-nextjs/blocks-react/components/Icons/ArrowDown/ArrowDown";
import { CatIconArrowUp } from "blocks-nextjs/blocks-react/components/Icons/ArrowUp/ArrowUp";
import { CatPagination } from "blocks-nextjs/blocks-react/components/Pagination/Pagination";
import { CatStatusIndicator } from "blocks-nextjs/blocks-react/components/StatusIndicator/StatusIndicator";
import { CatTable } from "blocks-nextjs/blocks-react/components/Table/Table";
import { CatTableBody } from "blocks-nextjs/blocks-react/components/TableBody/TableBody";
import { CatTableCell } from "blocks-nextjs/blocks-react/components/TableCell/TableCell";
import { CatTableHeader } from "blocks-nextjs/blocks-react/components/TableHeader/TableHeader";
import { CatTableHeaderCell } from "blocks-nextjs/blocks-react/components/TableHeaderCell/TableHeaderCell";
import { CatTableRow } from "blocks-nextjs/blocks-react/components/TableRow/TableRow";
import { format } from "date-fns";
import React, { useState } from "react";
import { getLocalTime } from "../../pages/Projects/ProjectDetails.helper";
const Alerts = (props) => {
    const { offset, timezone } = props;
    const [alertsSortOrder, setAlertsSortOrder] = useState("ASC");
    const faultsData = {
        faults: [
            {
                assetId: "ZYY12345",
                faultDescription: "Battery High Temperature 1",
                severity: {
                    name: "HIGH",
                    value: 1
                },
                timestamp: "2024-08-24T23:53:59Z"
            },
            {
                assetId: "ZYY12345",
                faultDescription: "Battery High Temperature 2",
                severity: {
                    name: "HIGH",
                    value: 1
                },
                timestamp: "2024-03-24T23:53:59Z"
            },
            {
                assetId: "ZYY12345",
                faultDescription: "Battery High Temperature 3",
                severity: {
                    name: "HIGH",
                    value: 1
                },
                timestamp: "2024-02-24T23:53:59Z"
            },
            {
                assetId: "ZYY12345",
                faultDescription: "Battery High Temperature 4",
                severity: {
                    name: "HIGH",
                    value: 1
                },
                timestamp: "2024-04-24T23:53:59Z"
            },
            {
                assetId: "ZYY12345",
                faultDescription: "Battery High Temperature 5",
                severity: {
                    name: "HIGH",
                    value: 1
                },
                timestamp: "2024-01-24T23:53:59Z"
            }
        ],
        responseMetadata: {
            nextCursor: "eyJvZmZzZXQiOjF9"
        }
    };
    const sortFault = () => {
        if (alertsSortOrder === "ASC") {
            setAlertsSortOrder("DESC");
        }
        else {
            setAlertsSortOrder("ASC");
        }
    };
    const getTimezoneAbbr = () => {
        const currentDate = new Date().toLocaleString("en-US", { timeZone: `${timezone}`, timeZoneName: "long" });
        const splittimezone = currentDate.split("AM ");
        const abbr = splittimezone?.length > 1 ? splittimezone[1] : currentDate.split("PM ")[1];
        const abbrShort = abbr
            .split(" ")
            .map((word) => word[0])
            .join("");
        return abbrShort;
    };
    const timezoneAbbr = timezone ? getTimezoneAbbr() : "";
    const populateFaultsData = () => {
        const { faults } = faultsData;
        return faults.map((fault, index) => {
            const formattedTimestamp = offset ? format(getLocalTime(fault.timestamp, offset), "dd MMM yyy HH:mm") : "";
            return (_jsxs(CatTableRow, { children: [_jsx(CatTableCell, { children: _jsxs("div", { className: "flex flex-column items-center ml-[15px]", children: [_jsx("span", { className: "mr-[15px]", children: index + 1 }), _jsx("span", { children: fault.faultDescription })] }) }), _jsx(CatTableCell, { children: _jsxs("div", { className: "flex justify-end items-center h-[32px]", children: [formattedTimestamp, " ", timezoneAbbr] }) })] }, index));
        });
    };
    return (_jsx("div", { className: "flex flex-col gap-3", "data-test": "alerts-container", children: _jsxs(React.Fragment, { children: [_jsxs(CatTable, { arialabel: "Default Table", "data-test": "alerts", children: [_jsx("div", { slot: "caption", children: "Default Table Description" }), _jsx(CatTableHeader, { "data-test": "cat-table-header", isFrozen: true, children: _jsxs(CatTableRow, { children: [_jsx(CatTableHeaderCell, { "data-test": "alerts-header-title", children: _jsxs("div", { className: "flex flex-column items-center", children: [_jsx(CatHeading, { className: "mr-[10px]", "data-test": "alert-header-text", variant: "title", children: "Alerts" }), _jsx(CatStatusIndicator, { className: "new-alerts", text: "4 New (24h)", variant: "warning" })] }) }), _jsx(CatTableHeaderCell, { "data-test": "sort-header-title", onClick: () => sortFault(), children: _jsx("div", { className: "flex justify-end items-center h-[32px]", children: alertsSortOrder === "DESC" ? (_jsxs(_Fragment, { children: [_jsx(CatIconArrowDown, { className: "items-end self-center height-15px mr-1" }), _jsx("span", { children: "Sort" })] })) : (_jsxs(_Fragment, { children: [_jsx(CatIconArrowUp, { className: "items-end self-center height-15px mr-1" }), _jsx("span", { children: "Sort" })] })) }) })] }) }), _jsx(CatTableBody, { children: populateFaultsData() })] }), _jsxs("div", { className: "flex justify-between items-center", children: [_jsx("div", { className: "w-1/3 ml-[15px]", children: " 15 Total " }), _jsx(CatPagination, { arialabel: "Pagination footer", pageSize: 5, pageSizeOptions: [10, 15], slot: "footer", totalRecords: 15 })] })] }) }));
};
export default Alerts;
