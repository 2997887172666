import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from "react";
import CustomGoogleMap from "./CustomGoogleMap";
import "./GoogleMaps.css";
const GoogleMaps = ({ addressDetails = [], selectedAddress, siteOffset, siteTimezone }) => {
    const coordinates = {
        lat: [],
        lng: []
    };
    addressDetails.forEach((details) => {
        coordinates.lat.push(Number(details.location[0]));
        coordinates.lng.push(Number(details.location[1]));
    });
    const averageLng = (Math.max(...coordinates.lng) + Math.min(...coordinates.lng)) / 2;
    const averageLat = (Math.max(...coordinates.lat) + Math.min(...coordinates.lat)) / 2;
    const mapCenter = useMemo(() => ({ lat: averageLat, lng: averageLng }), [averageLat, averageLng]);
    return (_jsx("div", { "data-test": "map-section", children: _jsx(CustomGoogleMap, { addressDetails: addressDetails, mapCenter: mapCenter, selectedAddress: selectedAddress, siteOffset: siteOffset, siteTimezone: siteTimezone }) }));
};
export default GoogleMaps;
