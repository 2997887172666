import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
// import { useAssetsChargingOptions } from "../../services/query/eflow-charge-operation-planner-api.query";
// import { CatLoadingIndicator } from "blocks-react/bedrock/components/LoadingIndicator/LoadingIndicator";
// import APIError from "../APIError/APIError";
import ChargingOptions from "./ChargingOptions";
const ChargingOptionsWrapper = (props) => {
    // const { assetId, offset, projectId, timezone } = props;
    const { offset, timezone } = props;
    // const {
    //     data: assetChargingOptionsData = {} as any,
    //     refetch: refetchChargingOptions,
    //     status: assetChargingOptionsApiStatus
    // } = useAssetsChargingOptions([assetId], projectId);
    const assetChargingOptionsData = {
        assets: [
            {
                assetId: "499dfa2c-0723-43c6-835c-ac347a5d83d6",
                chargeOptions: [
                    {
                        directChargeToMachine: {
                            completionTime: "55",
                            machineChargeTime: "47.5",
                            machineTargetSoc: "87.0",
                            sourceName: "ESS/BQ2",
                            totalTravelTime: "55"
                        },
                        essMetaData: {
                            assetId: "bbc72313-53f3-4c90-b159-e11d82d39d3c",
                            lastUpdatedSoCTimestamp: "2025-01-28T11:54:30",
                            make: "PSW",
                            model: "BAB",
                            reportingStatus: "Discharging",
                            serialNumber: "248333",
                            soc: "50.34671"
                        },
                        throughChargeStation: [
                            {
                                ESSChargeTime: "40",
                                additionalTravelTime: "95",
                                completionTime: "55",
                                machineChargeTime: "47.5",
                                machineTargetSoc: "100",
                                sourceName: "Watthub",
                                totalTravelTime: "55",
                                travelTimeToMachineFromChargeStation: "35",
                                travelTimetoChargeStation: "30"
                            }
                        ]
                    }
                ]
            }
        ]
    };
    const renderChargingOptions = () => {
        // if (assetChargingOptionsApiStatus === "pending") {
        //     return (
        //         <div className="flex justify-center items-center h-full min-h-[28vh] w-full">
        //             <CatLoadingIndicator />
        //         </div>
        //     );
        // } else if (assetChargingOptionsApiStatus === "error") {
        //     return <APIError refreshHandler={refetchChargingOptions} />;
        // } else if (assetChargingOptionsApiStatus === "success") {
        // if (Object.keys(assetChargingOptionsData).length === 0 || assetChargingOptionsData?.assets?.length === 0) {
        //     return <NoDataMessage />;
        // }
        return _jsx(ChargingOptions, { chargingOptionsData: assetChargingOptionsData, offset: offset, timezone: timezone });
        // }
    };
    return (_jsxs("div", { children: [_jsx("div", { className: "text-2xl font-bold charging-options mx-[30px] my-[22px]", "data-test": "charging-options", children: "Charging Options" }), renderChargingOptions()] }));
};
export default ChargingOptionsWrapper;
